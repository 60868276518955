import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import { ActionTypes, IUserProfile, TUser, TUserState } from './types';
import { TCompany } from '../../components/content/Profile/TCompany';

export const setAuth = (payload: boolean) => action(ActionTypes.SET_IS_AUTH, payload);
export const setUser = (payload: TUser | null) => action(ActionTypes.SET_USER, payload);
export const setRegistrationData = (payload: TUserState['registrationData'] | null) => action(ActionTypes.SET_REGISTRATION_DATA, payload);

const LogoutAction = new Actions('LOGOUT_USER', ActionTypes);

export interface TypeLogoutR extends TDefRequest {}

export const Logout = {
  request: (payload: TypeLogoutR) => LogoutAction.request(payload),
  success: (payload: TUser) => LogoutAction.success(payload || {}),
  error: (message: string) => LogoutAction.error(message),
};

const LoginUserAction = new Actions('LOGIN_USER', ActionTypes);

export interface TypeLoginUserR extends TDefRequest {
  data: {
    email: string;
    password: string;
  };
}

export const LoginUser = {
  request: (payload: TypeLoginUserR) => LoginUserAction.request(payload),
  success: (payload: TUser) => LoginUserAction.success(payload || {}),
  error: (message: string) => LoginUserAction.error(message),
};

export const GetFBUserAction = new Actions('FB_USER', ActionTypes);

export interface TypeGetFBUserR extends TDefRequest {
  data: {
    facebookUserId: string;
    facebookToken: string;
  };
}
export const GetFBUser = {
  request: (payload: TypeGetFBUserR) => GetFBUserAction.request(payload),
  success: (payload: TUser) => GetFBUserAction.success(payload || {}),
  error: (message: string) => GetFBUserAction.error(message),
};


export const GetGoogleUserAction = new Actions('GOOGLE_USER', ActionTypes);

export interface TypeGetGoogleUserR extends TDefRequest {
  data: {
    googleToken: string;
  };
}
export const GetGoogleUser = {
  request: (payload: TypeGetGoogleUserR) => GetGoogleUserAction.request(payload),
  success: (payload: TUser) => GetGoogleUserAction.success(payload || {}),
  error: (message: string) => GetGoogleUserAction.error(message),
};

export const upSocialDataAction = new Actions('SOCIAL_DATA', ActionTypes);

export interface TypeUpSocialDataR extends TDefRequest {
  data: {
    googleToken?: string;
    facebookToken?: string;
    facebookUserId?: string;
    email:string;
  };
}
export const upSocialData = {
  request: (payload: TypeUpSocialDataR) => upSocialDataAction.request(payload),
  success: (payload: TUser) => upSocialDataAction.success(payload || {}),
  error: (message: string) => upSocialDataAction.error(message),
};


const RegistrationUserAction = new Actions('REGISTRATION_USER', ActionTypes);

export interface TypeRegistrationUserR extends TDefRequest {
  data: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    job_id?: string;
    position?: string;
    phone?: string;
    redirectUrl? : string;
    facebookUserId? : string;
    googleUserID? : string
    facebookToken? : string;
    googleToken? : string 
  };
}

export const RegistrationUser = {
  request: (payload: TypeRegistrationUserR) => RegistrationUserAction.request(payload),
  success: (payload: any) => RegistrationUserAction.success(payload || {}),
  error: (message: string) => RegistrationUserAction.error(message),
};

const RegistrationUserSocAction = new Actions('REGISTRATION_USER_SOC', ActionTypes);

export const RegistrationUserSoc = {
  request: (payload: TypeRegistrationUserR) => RegistrationUserSocAction.request(payload),
  success: (payload: any) => RegistrationUserSocAction.success(payload || {}),
  error: (message: string) => RegistrationUserSocAction.error(message),
};


const CheckAccessAction = new Actions('CHECK_ACCESS', ActionTypes);

export interface TypeCheckAccessR extends TDefRequest {}

export const CheckAccess = {
  request: (payload: TypeCheckAccessR) => CheckAccessAction.request(payload),
  success: (payload: TUser) => CheckAccessAction.success(payload || {}),
  error: (message: string) => CheckAccessAction.error(message),
};

const ReqChangePasswdAction = new Actions('REQ_CHANGE_PASSWD', ActionTypes);

export interface TypeReqChangePasswdR extends TDefRequest {
  data: {
    email: string;
  };
}

export const ReqChangePasswd = {
  request: (payload: TypeReqChangePasswdR) => ReqChangePasswdAction.request(payload),
  success: () => ReqChangePasswdAction.success({}),
  error: (message: string) => ReqChangePasswdAction.error(message),
};

const ChangePasswdAction = new Actions('CHANGE_PASSWD', ActionTypes);

export interface TypeChangePasswdR extends TDefRequest {
  data: {
    password: string;
    token?: string;
  };
}

export const ChangePasswd = {
  request: (payload: TypeChangePasswdR) => ChangePasswdAction.request(payload),
  success: () => ChangePasswdAction.success({}),
  error: (message: string) => ChangePasswdAction.error(message),
};

const CheckTokenAction = new Actions('CHECK_TOKEN', ActionTypes);

export interface TypeCheckTokenR extends TDefRequest {
  data: {
    token: string;
  };
}

export const CheckToken = {
  request: (payload: TypeCheckTokenR) => CheckTokenAction.request(payload),
  success: () => CheckTokenAction.success({}),
  error: (message: string) => CheckTokenAction.error(message),
};

const GetUserProfileAction = new Actions('GET_USER_PROFILE', ActionTypes);

export interface TypeGetUserProfileR extends TDefRequest {}

export const GetUserProfile = {
  request: (payload: TypeGetUserProfileR) => GetUserProfileAction.request(payload),
  success: (payload: IUserProfile) => GetUserProfileAction.success(payload || {}),
  error: (message: string) => GetUserProfileAction.error(message),
};

const UpdateUserProfileAction = new Actions('UPDATE_USER_PROFILE', ActionTypes);

export interface TypeUpdateUserProfileR extends TDefRequest {
  data: {
    lastName: string;
    firstName: string;
    middleName?: string;
    birthdate?: string;
    phone?: string;
    job_id?: string;
    position?: string;
    company?: TCompany;
  };
}

export const UpdateUserProfile = {
  request: (payload: TypeUpdateUserProfileR) => UpdateUserProfileAction.request(payload),
  success: (payload: IUserProfile) => UpdateUserProfileAction.success(payload || {}),
  error: (message: string) => UpdateUserProfileAction.error(message),
};

const ActivateEmailAction = new Actions('ACTIVATE_EMAIL', ActionTypes);

export interface TypeActivateEmailR extends TDefRequest {
  email: string;
  letterId?: any;
}

export const ActivateEmail = {
  request: (payload: TypeActivateEmailR) => ActivateEmailAction.request(payload),
  success: (payload: string) => ActivateEmailAction.success(payload || {}),
  error: (message: string) => ActivateEmailAction.error(message),
};

const CheckEmailAction = new Actions('CHECK_EMAIL', ActionTypes);

export interface TypeCheckEmailR extends TDefRequest {
  email: string;
}

export const CheckEmail = {
  request: (payload: TypeCheckEmailR) => CheckEmailAction.request(payload),
  success: (payload: boolean) => CheckEmailAction.success(payload || {}),
  error: (message: string) => CheckEmailAction.error(message),
};


const CheckUserProfileAction = new Actions('CHECK_USER_PROFILE', ActionTypes);

export interface TypeCheckUserProfileR extends TDefRequest {
  data: any;
}

export const CheckUserProfile = {
  request: (payload: TypeCheckUserProfileR) => CheckUserProfileAction.request(payload),
  success: (payload: IUserProfile) => CheckUserProfileAction.success(payload || {}),
  error: (message: string) => CheckUserProfileAction.error(message),
};

const CheckUserPhoneAction = new Actions('CHECK_USER_PHONE', ActionTypes);

export interface TypeCheckUserPhoneR extends TDefRequest {
  phone: string;
}
export const CheckUserPhone = {
  request: (payload: TypeCheckUserPhoneR) => CheckUserPhoneAction.request(payload),
  success: (payload: {exists: boolean}) => CheckUserPhoneAction.success(payload || {}),
  error: (message: string) => CheckUserPhoneAction.error(message),
};