import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import '../styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetGoogleUser } from '../../../store/user/actions';
import ReactGA from 'react-ga4';

interface Props {
  appId?: string;
  redirect?: string;
  isLogin?: boolean;
  setIsSucc?: Function;
  setError : any;
  email? : string;
}
const GoogleAuthButton: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = (response : any) => {
    const successEvent ={
      category: 'User',
      action: 'login',
      label: 'Google OAuth Login Success',
      value: 1
    };
    ReactGA.event(successEvent);
    window.parent.postMessage(`{"GoogleAuthEvent": ${JSON.stringify(successEvent)}}`, '*');
    if(response?.credential){
      dispatch(
        GetGoogleUser.request({
          data: {
            googleToken : response.credential,
          },
          callBack: (succ: any, data: any) => {
            if (props.setIsSucc) {
              if (succ) {
                props.setIsSucc({
                  status: 'success',
                  url: '',
                  tokenCoock: data?.tokenCoock || `${JSON.stringify(data)} NO tokenCoock4`

                })
              } else {
                const getDataParam = `&email=${data?.email || ''}&firstName=${data?.firstName || ''}&lastName=${data?.lastName || ''}&googleUserID=${data?.googleUserID || ''}&googleToken=${data?.googleToken || ''}`
                props.setIsSucc({
                  status: 'register',
                  url: `/registration/?redirectUrl=${props.redirect}&app=${props.appId}${getDataParam}`,
                })
              }
            } else {
              if (!succ && data.message) {
                props.setError(data.message);
              }
              if (!succ && data.email && data.redirect) {
                if(props.redirect){
                  navigate( data.redirect);
                }else{
                  navigate(`/registration`);
                }
              }
              if (succ) {
                navigate('/main');
              }
            }
          },
        })
      );
    }

  };
  return (
    <div className='googleIcon'>
      <GoogleOAuthProvider clientId="1051577674611-1v5btgm5ag7rvlmem9okq0ef61ruqblg.apps.googleusercontent.com">
        <GoogleLogin
          onSuccess={login}
          onError={() => {
            console.log('Login Failed');
            const failedEvent ={
              category: 'User',
              action: 'login',
              label: 'Google OAuth Login Failed',
              value: 0
            };
            ReactGA.event(failedEvent);
            window.parent.postMessage(`{"GoogleAuthEvent": ${JSON.stringify(failedEvent)}}`, '*');
          }}
          type = {'icon'}
          size = {'large'}
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleAuthButton;
