import React, {useEffect} from "react";
import {GetGoogleUser} from "../../store/user/actions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

interface Props {
  isPersonalAccountWebsite: boolean;
}

export const AutoGoogleAuth0: React.FC<Props> = (
  {
    isPersonalAccountWebsite,
  }
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAlreadyLoaded, setIsAlreadyLoaded] = React.useState(false);
  const isGoogleChromeDesktop = () => {
    const ua = navigator.userAgent || navigator.vendor;
    return /Chrome/i.test(ua) && /Google Inc/i.test(navigator.vendor);
  }
  const handleCredentialResponse = (response: any) => {
    const {credential} = response;
    dispatch(
      GetGoogleUser.request({
        data: {
          googleToken: credential,
        },
        callBack: (succ: any, data: any) => {
          if (!data) return;
          if (succ) {
            if (isPersonalAccountWebsite) {
              console.log("Personal account website", data);
              if (!succ && data.message) {
                alert(data.message);
              }
              if (!succ && data.email && data.redirect) {
                navigate(`/registration`);
              }
              if (succ) {
                navigate('/main');
              }
            } else {
              console.log('Not personal account website', data);
              window.parent.postMessage({...data, status: 'success'}, "*");
            }
          } else {
            const getDataParam = `&email=${data?.email || ''}&firstName=${data?.firstName || ''}&lastName=${data?.lastName || ''}&googleUserID=${data?.googleUserID || ''}&googleToken=${data?.googleToken || ''}`
            window.parent.postMessage(
              {
                ...data,
                queryParams: `${getDataParam}`,
                status: 'register',
              },
              "*");
          }
        },
      })
    );
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const google_id = urlParams.get("google_id");

      (window as any).google.accounts.id.initialize({
        client_id: "1051577674611-1v5btgm5ag7rvlmem9okq0ef61ruqblg.apps.googleusercontent.com",
        callback: (response: any) => handleCredentialResponse(response),
        auto_select: !!google_id,
        login_hint: google_id || undefined,
        cancel_on_tap_outside: false,
        prompt_parent_id: "google-one-tap-container",
        intermediate_iframe_close_callback: (response: any) => {
          console.log("intermediate_iframe_close_callback", response);
          const iframe = document.getElementById("AutoGoogleAuth0");
          if (iframe) {
            iframe.remove();
          }
        },
      });

      (window as any).google.accounts.id.prompt();
    };

    setIsAlreadyLoaded(true);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  //find #credential_picker_iframe in the DOM and remove Auth0 iframe if it not exists
  useEffect(() => {
    if (!isAlreadyLoaded || isGoogleChromeDesktop()) return;
    const interval = setInterval(() => {
      const iframe = document.getElementById("credential_picker_iframe");
      if (!iframe) {
        window.parent.postMessage({status: 'close'}, "*");
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isAlreadyLoaded]);

  return <div id="google-one-tap-container"></div>;
};