import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { CheckEmail, RegistrationUser, LoginUser, setRegistrationData } from '../../store/user/actions';
import { collectionValidate } from '../../utils/collectionValidate';
import { DefaultInput, SelectSearch } from '../ui';
import { Header, Footer } from './components';
import queryString from 'query-string';
import s from './styles';

export const ButtonS = styled.button`
  height: 36px;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  background-color: #126979;
  background-image: linear-gradient(#257584 5%, #005e6f 80%);
  border-color: #3d8491 #11697a #004d5b;
  box-shadow: none;
  user-select: none;
  background-clip: padding-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 6px 16px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  &:hover {
    opacity: 0.92;
  }
`;

export const LogoBtnS = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  background: url(/media/images/rx-action-badge.png) 4px -10px no-repeat;
  font-family: HelveticaNeueCyr, Helvetica Neue, Helvetica, Arial, sans-serif !important;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  display: table-cell;
  width: 28px;
  height: 24px;
  text-align: center;
  vertical-align: top;
`;

const FlagsS = styled.div`
  border-right: 1px solid #d9d9d9;
  border-radius: 3px;
  position: absolute;
  width: 31px;
  height: 29px;
  background-image: url(/media/images/flags.png);
  background-repeat: no-repeat;
  background-position: 7px 9px;
`;

const FieldS = styled.div`
  margin-top: 15px;
  position: relative;
  &:before {
    clear: both;
    content: '';
    display: table;
  }
  &:after {
    clear: both;
    content: '';
    display: table;
  }
`;

const LabelS = styled.label`
  width: 136px;
  font-size: 14px;
  line-height: 18px;
  float: left;
  padding: 6px 15px 0 0;
  margin-bottom: -11px;
`;

const FieldControlS = styled.div`
  width: 489px;
  position: relative;
  float: left;
`;

const FieldPhone = styled.input`
  width: 160px;
  font-size: 14px;
  line-height: 16px;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  box-shadow: inset 0 2px 3px -1px #efefef;
  color: #000;
  display: inline-block;
  padding: 6px 7px;
  vertical-align: baseline;
  height: 31px;
  outline: none;
  &:focus {
    border: 1px solid var(--blueColor);
  }
`;

const FieldError = styled.span`
  left: 458px;
  margin-top: 7px;
  margin-left: 0;
  font-size: 12px;
  line-height: 18px;
  color: #e21b00;
  display: block;
  position: absolute;
  top: 0;
  z-index: 10;
`;
const FieldEye = styled.span`
  left: 423px;
  margin-top: 7px;
  position: absolute;
  cursor:pointer

`;
const FieldPass = styled.span`
  left: 458px;
  margin-top: 7px;
  margin-left: 0;
  font-size: 12px;
  line-height: 18px;
  display: block;
  position: absolute;
  top: 0;
  z-index: 10;
`;

const PanelS = styled.div`
  background: #daeef2;
  margin: 40px 0 52px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 3px;
  padding: 15px 13px 27px;
`;

const HelpBlockS = styled.div`
  font-size: 12px;
  line-height: 14px;
  display: block;
  margin: 7px 0 9px;
  margin-bottom: 0px;
`;

const TitleS = styled.h1`
  font-size: 36px;
  line-height: 45px;
  margin: 0 0 30px;
`;

type TypeValue = 'lastName' | 'firstName' | 'password' | 'position' | 'email';

const uaCode = '+380 ';
const errorEmailExist = (email: string) => `
<div><div>Ви вже реєструвалися</div><a href="/login?email=${email}">Увійдіть на сайт</a><span> або </span><a href="/login?email=${email}">відновіть пароль</a></div>
`;

const ERROR_PHONE = 'Номер телефону має неправильне значення';

const setPhoneFormat = (value: string) => {
  if (!value) return '';
  return value
    .split('')
    .map((it, i) => (i === 1 || i === 4 || i === 6 ? `${it} ` : it))
    .join('');
};

export const Registration: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [position, setPosition] = React.useState('');
  const [phone, setPhone] = React.useState(uaCode);
  const [error, setError] = React.useState<{ [x: string]: string }>({});
  const [errors, setErrors] = React.useState('');
  const [isSocial, setIsSocial] = React.useState(false);
  const [redonlyEmail, setRedonlyEmail] = React.useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const { User, Configurations } = useSelector((store: AppStore) => store);
  const params = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [letterId, setLetterId] = React.useState('');

  const handleChangeError = (type: string, mess?: string) => {
    if (error[type] && !mess) {
      const obj = { ...error };
      delete obj[type];
      setError(obj);

    }
    if (mess) {
      const obj = { ...error };
      obj[type] = mess;
      setError(obj);
    }

  };
  React.useEffect(() => {
    if(!isSocial){
      if (User?.data?.facebookUserId || User?.data?.googleUserID) {
        if (User?.data?.email) {
          setEmail(User.data.email.trim() as string);
          setRedonlyEmail(true)
        }
        if (User?.data?.firstName ) setFirstName(User.data.firstName as string);
        if (User?.data?.lastName ) setLastName(User.data.lastName as string);
        if(!password){
          setPassword(generateRandomPassword());
        }
        setIsPasswordVisible(true);
        setIsSocial(true)
        window.resizeTo(1200, 900);
    }else{
      const urlParams = new URL(window.location.href).searchParams;
      const email = urlParams.get('email')?.toString();
      if (email ) setEmail(email as string);

      const firstName = urlParams.get('firstName')?.toString();
      if (firstName ) setFirstName(firstName as string);
      
      const lastName = urlParams.get('lastName')?.toString();
      if (lastName ) setLastName(lastName as string);
      
      if(!password){
        setPassword(generateRandomPassword());
      }
      setIsPasswordVisible(true);
      setIsSocial(true)
      window.resizeTo(1200, 900);

    }
    }

  }, [User, password, isSocial]);

  const generateRandomPassword = () => {
    const passwordLength = 10;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    // перевірка наявності цифри в паролі
    if (!/\d/.test(password)) {
      // якщо цифри немає, додати одну випадкову цифру
      const randomDigitIndex = Math.floor(Math.random() * 10);
      password = password.slice(0, randomDigitIndex) + Math.floor(Math.random() * 10) + password.slice(randomDigitIndex + 1);
    }
    return password;
  }
  const login = () => {
    dispatch(
      LoginUser.request({
        data: {
          email,
          password,
        },
        callBack: (succ, data) => {
          console.log(succ);
        },
      })
    );
  }

  const handleSave = () => {
    const curentPosition = Configurations.data?.positions.find((it) => it.Name === position);
    let redirectUrl = '';
    if (params?.search) {
      const obj = queryString.parse(params?.search.substring(1));
      for (const key in obj) {
        if (key === 'redirectUrl') {
          redirectUrl = obj[key] as string;
        }
      }
    }
    setErrors('');
    const urlParams = new URL(window.location.href).searchParams;
    const facebookUserId = urlParams.get('facebookUserId')?.toString();
    const facebookToken = urlParams.get('facebookToken')?.toString();
    const googleUserID = urlParams.get('googleUserID')?.toString();
    const googleToken = urlParams.get('googleToken')?.toString();
    const data = {
      email,
      password,
      lastName,
      firstName,
      job_id: curentPosition ? curentPosition.Id : '',
      position: '',
      phone: phone ? phone.replace(uaCode, '').replace(/[^0-9.]/g, '') : '',
      redirectUrl : redirectUrl,
      facebookUserId : User?.data?.facebookUserId || facebookUserId || '',
      facebookToken : User?.data?.facebookToken || facebookToken || '',
      googleUserID:  User?.data?.googleUserID || googleUserID || '',
      googleToken:  User?.data?.googleToken || googleToken || '',

    };
    const obj: { [x: string]: string } = {};
    for (const key in data) {
      if (key === 'email' || key === 'password' || key === 'lastName' || key === 'firstName' || key === 'phone') {
        const field = collectionValidate[key];
        if (!data[key]) {
          obj[key] = 'Обов’язкове поле';
        } else if (!field.checkValue(data[key])) {
          obj[key] = field.error;
        }
      }
      if (key === 'phone' && data.phone && data.phone.length < 9) {
        obj[key] = ERROR_PHONE;
      }
      if (key === 'job_id' && !data.job_id && !data.position) {
        obj['position'] = 'Оберіть необхідну посаду';
      }
      if (key === 'position' && !collectionValidate[key].checkValue(data[key])) {
        obj['position'] = collectionValidate[key].error;
      }
    }
    const keys = Object.keys(obj);
    if (keys.length > 0) {
      return setError(obj);
    }
      dispatch(
        RegistrationUser.request({
          data,
          callBack: (success, data: { code: number; value: string; message: string; letterId: any; }) => {
            if(success){
              if (data.value === 'autoLogin') {
                login();
              } else {
                dispatch(setRegistrationData({ email, letterId: data.letterId }));
                navigate('/login');
              }
            } else {
              if(data.code === 409 && data.value === 'email') {
                handleChangeError(data.value, errorEmailExist(email));
              } else if  (data.value === 'id' && data.message) {
                setErrors(data.message);
              } else if (data.value && data.message) {
                handleChangeError(data.value, data.message);
              } else {
                setErrors('Сталася помилка');
              }
            }
          },
        })
      );
   
  };
  const handleCheckEmail = () => {
    if (!email || error['email']) return null;
    dispatch(
      CheckEmail.request({
        email,
        callBack: (succ, data) => {
          if (succ && !data) {
            handleChangeError('email', errorEmailExist(email));
          }
        },
      })
    );
  };

  const handleChange = (value: string, type: TypeValue, callBack: (value: string) => void) => {
    if( type === 'email'){
      value = value.replace(/ /g, '').replace(/\t/g, '').replace(/\u2003/g, '');
    }    
    if (collectionValidate[type].checkValue(value)) handleChangeError(type);
    else handleChangeError(type, collectionValidate[type].error);
    callBack(value);
  };

  const handleChangePosition = (value: string) => {
    handleChangeError('position');
    setPosition(value);
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handleChangeError('phone');
    if (value.length > 4) {
      if (value.length > phone.length) {
        const str = value
          .replace(uaCode, '')
          .replace(/[^0-9.]/g, '')
          .replaceAll('.', '');
        if (!Number.isNaN(Number(str))) {
          const ph = setPhoneFormat(str);
          setPhone(`${uaCode}${ph}`);
        }
      } else {
        setPhone(value);
      }
    }
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>)=> {
    if(e.key === '@' && email.includes('@')) {
      e.preventDefault();
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <Header />
      <s.WrapperS style={{ paddingLeft: '35px', paddingRight: '35px' }}>
        <div>
          <TitleS>Реєстрація</TitleS>
          <FieldS style={{ margin: 0 }}>
            <LabelS htmlFor="email">Ел. пошта</LabelS>
            <FieldControlS>
              <DefaultInput
                styleGroup={{ margin: 0 }}
                styleWrap={{ padding: 0 }}
                style={{ width: '307px', height: '32px', borderColor: error.email ? '#E8714A' : '' }}
                type="text"
                isRedonly = {!!redonlyEmail}
                handleFocusout={handleCheckEmail}
                handleChange={(value) => handleChange(value, 'email', setEmail)}
                value={email}
                handleKeyPress={handleKeyPress}
              />
              <HelpBlockS>Надішлемо посилання для підтвердження реєстрації</HelpBlockS>
            </FieldControlS>
            {error.email && <FieldError dangerouslySetInnerHTML={{ __html: error.email }} />}
          </FieldS>
          <FieldS style={{ marginBottom: '45px' }}>
            <LabelS htmlFor="password">Придумайте пароль</LabelS>
            <FieldControlS>
              <DefaultInput
                styleGroup={{ margin: 0 }}
                styleWrap={{ padding: 0 }}
                style={{ width: '307px', height: '32px', borderColor: error.password ? '#E8714A' : '' }}
                type={isPasswordVisible ? "text" : "password"} maxLength={20}
                handleChange={(value) => handleChange(value, 'password', setPassword)}
                value={password}
              />
            </FieldControlS>
            <FieldEye onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
              {isPasswordVisible ? (
              <img src="https://dev.id3.expertus.com.ua/media/images/eye_passw.png" alt="" />

              ):(
                <img src="https://dev.id3.expertus.com.ua/media/images/hidden.png" alt="" />

              )}
              </FieldEye>
            {!error.password && isSocial ? (
              <FieldPass>Пароль згенеровано автоматично</FieldPass>
            ) : '' }
            {error.password && <FieldError>{error.password}</FieldError>}
          </FieldS>
          <FieldS>
            <LabelS htmlFor="firstName">Ім’я</LabelS>
            <FieldControlS>
              <DefaultInput
                styleGroup={{ margin: 0 }}
                styleWrap={{ padding: 0 }}
                style={{ width: '307px', height: '32px', borderColor: error.firstName ? '#E8714A' : '' }}
                type="text"
                maxLength={50}
                handleChange={(value) => {
                  handleChange(value, 'firstName', setFirstName);
                }}
                value={firstName}
              />
            </FieldControlS>
            {error.firstName && <FieldError>{error.firstName}</FieldError>}
          </FieldS>
          <FieldS style={{ marginBottom: '45px' }}>
            <LabelS htmlFor="lastName">Прізвище</LabelS>
            <FieldControlS>
              <DefaultInput
                styleGroup={{ margin: 0 }}
                styleWrap={{ padding: 0 }}
                style={{ width: '307px', height: '32px', borderColor: error.lastName ? '#E8714A' : '' }}
                type="text"
                maxLength={50}
                handleChange={(value) => {
                  handleChange(value, 'lastName', setLastName);
                }}
                value={lastName}
              />
            </FieldControlS>
            {error.lastName && <FieldError>{error.lastName}</FieldError>}
          </FieldS>

          <FieldS style={{ marginBottom: '45px' }}>
            <LabelS htmlFor="position">Посада</LabelS>
            <FieldControlS style={{ marginTop: '-27px' }}>
              <HelpBlockS style={{ marginBottom: '5px' }}>Почніть уводити назву посади і оберіть необхідну із запропонованих.</HelpBlockS>
              <SelectSearch
                styleGroup={{ margin: 0 }}
                styleWrap={{ padding: 0 }}
                style={{ width: '307px', height: '32px', borderColor: error.position ? '#E8714A' : '' }}
                styleSelect={{ width: '307px' }}
                type="text"
                handleChange={handleChangePosition}
                value={position}
                options={Configurations.data ? Configurations.data.positions.map((it) => ({ value: it.Name, label: it.Name })) : []}
              />
            </FieldControlS>
            {error.position && <FieldError>{error.position}</FieldError>}
          </FieldS>

          <FieldS>
            <LabelS htmlFor="phone">Моб. телефон</LabelS>
            <FieldControlS>
              <FlagsS />
              <FieldPhone
                style={{ width: '307px',paddingLeft: '36px', borderColor: error.phone ? '#E8714A' : '' }}
                id="phone"
                name="phone"
                type="tel"
                maxLength={17}
                value={phone}
                onChange={handleChangePhone}
              />
              <HelpBlockS>Приклад: +380 44 123 45 67</HelpBlockS>
            </FieldControlS>
            {error.phone && <FieldError >{error.phone}</FieldError>}
          </FieldS>

          <PanelS>
            <FieldS>
              <FieldControlS style={{ marginLeft: '136px' }}>
                <p
                  style={{
                    fontSize: '13px',
                    margin: '13px 0',
                  }}
                >
                  Перш ніж заповнити форму реєстрації, ознайомтеся з умовами
                  <a href="/agreement" target="_blank" style={{ marginLeft: '5px' }}>
                    <u>угоди користувача</u>
                  </a>
                </p>
                <ButtonS disabled={User.loading} onClick={handleSave} style={{ height: '36px' }}>
                  {User.loading ? 'Зачекайте' : 'Зареєструватися'}
                </ButtonS>
                {errors ? <div style={{ paddingTop: '15px', color: 'red' }}>{errors}</div> : null}
              </FieldControlS>
            </FieldS>
          </PanelS>
        </div>
      </s.WrapperS>
      <Footer />
    </div>
  );
};
