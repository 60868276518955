import React from 'react';
import { Header, Footer } from './components';
import s from './styles';

interface Props {
  email: string;
  onResendEmail: (callback: (success: boolean, message?: string) => void) => void;
}

export const RegistrationSuccess: React.FC<Props> = ({ email, onResendEmail }) => {
  const [status, setStatus] = React.useState<'sent' | 'error' | ''>('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleClick = () => {
    setStatus('sent');
    onResendEmail((success, message) => {
      if (!success) {
        setStatus('error');
        setErrorMessage(message || 'Сталася помилка');
      }
    });
  };

  return (
    <div style={{ height: '100%' }}>
      <Header />
      <s.WrapperS>
        <s.FeedbackS>
          <h2>Ми надіслали Вам лист — перевірте, будь ласка, електронну пошту.</h2>
          <p>
            Ми відправили лист для підтвердження реєстрації на електронну адресу {email}. Він надійде менше ніж за хвилину. Якщо листа немає у вхідних
            повідомленнях, перевірте папку «Спам» чи «Розсилки» — інколи листи потрапляють туди.
          </p>
          <s.ResendContainer>
            <div style={{ marginBottom: '10px' }}>Перевірили електронну пошту, а листа немає?</div>
            <button onClick={handleClick}>Надіслати лист ще раз</button>
            {status === 'sent' && <s.Message color="green">Лист відправлено</s.Message>}
            {status === 'error' && <s.Message color="brown">{errorMessage}</s.Message>}
          </s.ResendContainer>
        </s.FeedbackS>
      </s.WrapperS>
      <Footer />
    </div>
  );
};
