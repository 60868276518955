import React, {useEffect} from 'react';
import { Newsletters } from '../components/content';

export const NewslettersFrame: React.FC = () => {
  const isFrame = window !== window.parent;
  const [newsletters, setNewsletters] = React.useState<string[]>([]);
  const [refNewsLetter, setRefNewsLetter] = React.useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (refNewsLetter) {
      const sendHeight = () => {
        const height = refNewsLetter.scrollHeight + 40;
        window.parent.postMessage({ type: 'widget_newsletters_resize', height }, '*');
      }
   
      const resizeObserver = new ResizeObserver(sendHeight);
  
      resizeObserver.observe(refNewsLetter);
  
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [refNewsLetter]);

  useEffect(() => {
    if(isFrame){
      const query = window.location.search.substring(1);
      const decodedQueries: string[] = [];
      query.split('&').forEach(param => {
        const [key, value] = param.split('=');
        if (key === 'newsletters[]') {
          decodedQueries.push(decodeURIComponent(value));
        }
      });
      setNewsletters(decodedQueries);
    }
  }, [isFrame]);
  if(!isFrame) return null;
  return (
        <Newsletters setRef={setRefNewsLetter} isFrame={true} newsletters={newsletters}/>
  );
};
