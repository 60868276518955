// @ts-nocheck
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import '../styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetFBUser } from '../../../store/user/actions';

const FaceBookAuthAndroidFB = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState(null);
  const [fbError, setFbError] = React.useState(null);

  const login = () => {
    if (!userData?.facebookUserId) return;

    dispatch(
      GetFBUser.request({
        data: userData,
        callBack: (succ, data) => {
          console.log('Backend response:', { succ, data });

          if (props.setIsSucc) {
            if (succ) {
              props.setIsSucc({
                status: 'success',
                url: '',
                tokenCoock: data?.tokenCoock || `${JSON.stringify(data)} NO tokenCoock`,
              });
            } else {
              const getDataParam = `&email=${data?.email ? data.email : ''}&firstName=${data?.firstName ? data?.firstName : ''}&lastName=${data?.lastName ? data?.lastName :''}&facebookUserId=${data?.facebookUserId ? data?.facebookUserId : ''}&facebookToken=${data?.facebookToken ? data?.facebookToken : ''}`
              if (data.redirect) {
                props.setIsSucc({
                  status: !props.autoAuthFb ? 'register' : 'close',
                  url: `${data.redirect}${getDataParam}`,
                });
              }
            }
          } else {
            if (!succ && data.message) props.setError(data.message);
            if (!succ && data.redirect) navigate(data.redirect || '/registration');
            if (succ) navigate('/main');
          }
        },
      })
    );
  };

  const callbackFunc = (response) => {
    console.log('Facebook login response:', response);
    if (response.status === 'unknown') {
      setFbError('Не вдалося авторизуватись через Facebook');
      return;
    }

    if (response.accessToken && response.userID) {
      setUserData({
        facebookToken: response.accessToken,
        facebookUserId: response.userID,
      });
    } else {
      setFbError('Помилка авторизації через Facebook');
    }
  };

  React.useEffect(() => {
    if (userData && !!props?.autoAuthFb) login();
  }, [userData, props?.autoAuthFb]);
  return (
    <>
      {fbError && <p style={{ color: 'red' }}>{fbError}</p>}
      <div style={{ display: userData?.facebookUserId ? 'none' : 'block' }}>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          callback={callbackFunc}
          fields="id,name,email,picture"
          scope="email"
          disableMobileRedirect={false}
          cssClass='facebookIcon'
          redirectUri={`${window.location.origin}/login`}
          autoLoad={true}
          textButton=''
        />
      </div>
      {userData?.facebookUserId ? (
        <div className='facebookIcon' onClick={login}></div>
      ) : null}
    </>
  );
};

export default FaceBookAuthAndroidFB;
