import React from 'react';
import { ArrowIcon } from '../../svgs/ArrowIcon';
import { QuestionIcon } from '../../svgs/QuestionIcon';
import s from './styles';
import styled from 'styled-components';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { ISupport } from '../../../store/configurations/types';

const DocumentS = styled.div<{ last?: boolean }>`
  border-top: 1px solid var(--warmGrayColor3);
  background-color: var(--whiteColor);
  border-radius: 0;
  cursor: pointer;
  ${({ last }) => (last ? 'border-bottom: 1px solid var(--warmGrayColor3);' : '')}
`;

const DocumentHeadS = styled.div<{ last?: boolean }>`
  font-weight: 600;
  font-size: 18px;
  padding: 16px 0;
  line-height: 22px;
  letter-spacing: -0.02px;
  color: var(--warmGrayColor7);
  display: flex;
  align-items: center;
`;

const WrappIconS = styled.div<{ isFocus?: boolean }>`
  margin-left: auto;
  margin-right: 8px;
  & svg {
    ${({ isFocus }) => (isFocus ? 'transform: rotate(180deg);' : '')}
  }
`;

const AccorditS = styled.div`
  cursor: text;
  border-top: none;
  font-size: 16px;
  line-height: 24px;
  max-width: 100%;
  margin-left: 28px;
  padding-bottom: 24px;
  p {
    line-height: 24px;
    margin: 17px 0;
  }
  ul {
    padding-left: 20px;
    margin: 17px 0;
  }
  img {
    max-width: 100%;
    cursor: default;
  }
  iframe {
    max-width: 100%;
  }
`;

function useQuery() {
  const { search } = useLocation();

  const parseSearch = (str: string) => {
    const obj: { [key: string]: string } = {};
    if (typeof str !== 'string') return obj;
    str
      .replace('?', '')
      .split('&')
      .forEach((it) => {
        const [key, value] = it.split('=');
        if (key && value) {
          obj[key] = value;
        }
      });
    return obj;
  };

  return React.useMemo(() => parseSearch(search), [search]);
}

export const SupportAnswers: React.FC = () => {
  const [support, setSupport] = React.useState<ISupport | null>(null);
  const [qustionId, setQustionId] = React.useState<number | null>(null);
  const [isScroll, setIsScroll] = React.useState(true);

  const { Configurations } = useSelector((store: AppStore) => store);

  const navigate = useNavigate();
  const { id } = useParams();
  const { docId } = useQuery();

  const moveScroll = React.useCallback(
    (ev: HTMLDivElement | null) => {
      if (ev && isScroll) {
        ev.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [isScroll]
  );

  React.useEffect(() => {
    const questId = Number(docId);
    if (!Number.isNaN(questId)) {
      setQustionId(questId);
    }
  }, [docId]);

  React.useEffect(() => {
    const partId = Number(id);
    if (Configurations.data?.support && !Number.isNaN(partId)) {
      const main = Configurations.data.support.find((it) => it.id === partId);
      if (main) {
        setSupport(main);
      }
    }
  }, [Configurations.data?.support, id]);
  const isTechnicalBlock = support?.id === 3;
  return (
    <s.WrapperS>
      <s.MainS>
        <div>
          <s.TitleS>{support?.name || ''}</s.TitleS>
          {isTechnicalBlock ? null : <s.LineS />}
        </div>
        <div>
          {support?.children?.map((it, i) => {
            return (
              <div style={{ marginBottom: '64px' }} key={`${i}-${it.id}`}>
                {isTechnicalBlock ? null : <s.SubTitle style={{ marginBottom: '24px' }}>{it.name}</s.SubTitle>}
                {it.questions?.map((q, index) => {
                  return (
                    <DocumentS
                      last={it.questions.length - 1 === index}
                      key={`${i}-${index}-${it.id}-${q.id}`}
                      ref={(e) => q.id === qustionId && moveScroll(e)}
                    >
                      <DocumentHeadS
                        onClick={() => {
                          setIsScroll(false);
                          console.log(q.id === qustionId);
                          if (q.id === qustionId) {
                            navigate(`/feedback/${support.id}?docId=0`);
                          } else {
                            navigate(`/feedback/${support.id}?docId=${q.id}`);
                          }
                        }}
                      >
                        <span style={{ marginRight: '8px' }}>
                          <QuestionIcon />
                        </span>
                        {q.name}
                        <WrappIconS isFocus={q.id === qustionId}>
                          <ArrowIcon />
                        </WrappIconS>
                      </DocumentHeadS>
                      {q.id === qustionId ? <AccorditS dangerouslySetInnerHTML={{ __html: q.body }} /> : null}
                    </DocumentS>
                  );
                })}
              </div>
            );
          })}
        </div>
      </s.MainS>
    </s.WrapperS>
  );
};
