import { TCompany } from "../../components/content/Profile/TCompany";

export interface TUser {
  token: string;
  job_id: string;
  phone: string | null;
  absnum: number;
  email: string;
  fio: string;
  type: string;
  firstName : string;
  lastName : string;
  facebookUserId? : string;
  googleUserID? : string;
  facebookToken? : string;
  googleToken? : string;
  tokenCoock : string;
  verifyPhone?: number
}
export interface TUserError {
  message: string;
}
export interface TUserState {
  userProfile: IUserProfile | null;
  readonly data: TUser | null;
  readonly loading: boolean;
  isAuth?: boolean;
  errors?: TUserError | string | undefined;
  registrationData?: {
    email: string
    letterId: number
  } | null
}

export interface IUserProfile {
  id: number;
  email: string;
  lname: string;
  fname: string;
  mname: string;
  bday: Date | null;
  job_id: string;
  position: string;
  company: string | null;
  phone: string | null;
  verifyPhone: number
  confirm_data?: string | null;
  companies?: TCompany[];
  e_subscriber: number;
  s_subscriber: number;
  p_subscriber: number;
  sch_subscriber: number;
  demo_e: number;
  demo_s: number;
  authorized: number;
}

export enum ActionTypes {
  ACTIVATE_EMAIL_R = '@@user/ACTIVATE_EMAIL_R',
  ACTIVATE_EMAIL_S = '@@user/ACTIVATE_EMAIL_S',
  ACTIVATE_EMAIL_E = '@@user/ACTIVATE_EMAIL_E',

  LOGOUT_USER_R = '@@user/LOGOUT_USER_R',
  LOGOUT_USER_S = '@@user/LOGOUT_USER_S',
  LOGOUT_USER_E = '@@user/LOGOUT_USER_E',

  LOGIN_USER_R = '@@user/LOGIN_USER_R',
  LOGIN_USER_S = '@@user/LOGIN_USER_S',
  LOGIN_USER_E = '@@user/LOGIN_USER_E',

  FB_USER_R = '@@user/FB_USER_R',
  FB_USER_S = '@@user/FB_USER_S',
  FB_USER_E = '@@user/FB_USER_E',

  GOOGLE_USER_R = '@@user/GOOGLE_USER_R',
  GOOGLE_USER_S = '@@user/GOOGLE_USER_S',
  GOOGLE_USER_E = '@@user/GOOGLE_USER_E',
 
  SOCIAL_DATA_R = '@@user/SOCIAL_DATA_R',
  SOCIAL_DATA_S = '@@user/SOCIAL_DATA_S',
  SOCIAL_DATA_E = '@@user/SOCIAL_DATA_E',

  REGISTRATION_USER_R = '@@user/REGISTRATION_USER_R',
  REGISTRATION_USER_S = '@@user/REGISTRATION_USER_S',
  REGISTRATION_USER_E = '@@user/REGISTRATION_USER_E',

  CHECK_ACCESS_R = '@@user/CHECK_ACCESS_R',
  CHECK_ACCESS_S = '@@user/CHECK_ACCESS_S',
  CHECK_ACCESS_E = '@@user/CHECK_ACCESS_E',

  REQ_CHANGE_PASSWD_R = '@@user/REQ_CHANGE_PASSWD_R',
  REQ_CHANGE_PASSWD_S = '@@user/REQ_CHANGE_PASSWD_S',
  REQ_CHANGE_PASSWD_E = '@@user/REQ_CHANGE_PASSWD_E',

  CHANGE_PASSWD_R = '@@user/CHANGE_PASSWD_R',
  CHANGE_PASSWD_S = '@@user/CHANGE_PASSWD_S',
  CHANGE_PASSWD_E = '@@user/CHANGE_PASSWD_E',

  CHECK_TOKEN_R = '@@user/CHECK_TOKEN_R',
  CHECK_TOKEN_S = '@@user/CHECK_TOKEN_S',
  CHECK_TOKEN_E = '@@user/CHECK_TOKEN_E',

  GET_USER_PROFILE_R = '@@user/GET_USER_PROFILE_R',
  GET_USER_PROFILE_S = '@@user/GET_USER_PROFILE_S',
  GET_USER_PROFILE_E = '@@user/GET_USER_PROFILE_E',

  UPDATE_USER_PROFILE_R = '@@user/UPDATE_USER_PROFILE_R',
  UPDATE_USER_PROFILE_S = '@@user/UPDATE_USER_PROFILE_S',
  UPDATE_USER_PROFILE_E = '@@user/UPDATE_USER_PROFILE_E',

  CHECK_EMAIL_R = '@@user/CHECK_EMAIL_R',
  CHECK_EMAIL_S = '@@user/CHECK_EMAIL_S',
  CHECK_EMAIL_E = '@@user/CHECK_EMAIL_E',

  SET_IS_AUTH = '@@user/SET_IS_AUTH',
  SET_USER = '@@user/SET_USER',
  SET_REGISTRATION_DATA = '@@user/SET_REGISTRATION_DATA',

  USER_CLEAN_UP = '@@user/USER_CLEAN_UP',

  CHECK_USER_PROFILE_R = '@@user/CHECK_USER_PROFILE_R',
  CHECK_USER_PROFILE_S = '@@user/CHECK_USER_PROFILE_S',
  CHECK_USER_PROFILE_E = '@@user/CHECK_USER_PROFILE_E',

  CHECK_USER_PHONE_R = '@@user/CHECK_USER_PHONE_R',
  CHECK_USER_PHONE_S = '@@user/CHECK_USER_PHONE_S',
  CHECK_USER_PHONE_E = '@@user/CHECK_USER_PHONE_E',
}
